import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import './App.css'
import MainLayout from './pages/Common/MainLayout'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import lightBlue from '@material-ui/core/colors/lightBlue'
import PrintBody from './pages/Common/PrintBody'
import NotFound from './pages/Common/NotFound'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: lightBlue[500],
            contrastText: '#fff'
        }
    },
    overrides: {
        MUIDataTableBodyCell: {
            root: {
                paddingTop: 0,
                paddingBottom: 0
            }
        }
    }
})

function App() {
    return (
        <React.StrictMode>
            <Router>
                <ThemeProvider theme={theme}>
                    <Switch>
                        <Route path='/print' exact component={PrintBody} />
                        <Route path='/' component={MainLayout} />
                    </Switch>
                </ThemeProvider>
            </Router>
        </React.StrictMode>
    )
}

export default App
