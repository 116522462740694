import { Toolbar, LinearProgress } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import Axios from 'axios'
import jwtDecode from 'jwt-decode'
import { SnackbarProvider } from 'notistack'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import BottomNav from '../../components/Navigation/BottomNav'
import TitleBar from '../../components/Navigation/TitleBar'
import routes from '../../utils/routes'
import Loading from './Loading'
import { makeStyles } from '@material-ui/core/styles'
import { Fab } from '@material-ui/core'
import { SvgIcon } from '@material-ui/core'
import { Typography } from '@material-ui/core'

export const main_config = React.createContext(null)

const useStyles = makeStyles(theme => ({
    fab: {
        margin: theme.spacing(1)
    },
    extendedIcon: {
        marginRight: theme.spacing(1)
    },
    titleRoot: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
    }
}))

export default function MainLayout(props) {
    const classes = useStyles()
    const query = queryString.parse(props.location.search)
    const [title, setTitle] = React.useState('发票管理')
    const [disShadow, setDisShadow] = React.useState(false)
    const [profile, setProfile] = useState({})

    const [login, setLogin] = useState(false)

    useEffect(() => {
        if (query.code) {
            ;(async () => {
                let res = await Axios.post(`${process.env.REACT_APP_API_URL}/wechat/login`, {
                    code: query.code,
                    state: 'testState'
                })
                if (res.status === 200) {
                    let { token } = res.data

                    // const token =
                    //     'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1ZDI4OTZhZWIzYjBlOTE4MGM3MTI3YTIifQ.pZUeKJVlLrz-7rY2VZ8SZlWt6_YKLLq_EFQL4IlwKJE'

                    const data = jwtDecode(token)

                    res = await Axios.get(`${process.env.REACT_APP_API_URL}/users/${data._id}`)
                    console.log(res.data)
                    setProfile(res.data)
                }
            })()
        } else {
            setLogin(true)
        }

        // ;(async () => {
        //     const res = await Axios.get(`${process.env.REACT_APP_API_URL}/users/5d348806984f7914c8d29490`)
        //     console.log(res.data)
        //     setProfile(res.data)
        // })()
    }, [])

    useEffect(() => {
        console.log(profile)
    }, [profile])

    const handleLogin = () => {
        const response_type = 'code'
        const scope = 'snsapi_userinfo'
        const desktopScope = 'snsapi_login'
        const state = 'testState'

        const reqUrl = `https://open.weixin.qq.com/connect/qrconnect?appid=${
            process.env.REACT_APP_WECHAT_APPID
        }&redirect_uri=${
            process.env.REACT_APP_WECHAT_REDIRECTURL
        }&response_type=${response_type}&scope=${desktopScope}&state=${state}#wechat_redirect`

        window.location.href = reqUrl
    }

    return (
        <CssBaseline>
            <TitleBar title={title} disShadow={disShadow} />
            <main_config.Provider
                value={{
                    setTitle,
                    setDisShadow,
                    profile,
                    setProfile
                }}
            >
                <SnackbarProvider
                    maxSnack={3}
                    autoHideDuration={1500}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    {profile.roles && profile.roles[0] !== 'U' ? (
                        <Switch>
                            {routes.map(route => (
                                <Route key={route.title} {...route} />
                            ))}
                        </Switch>
                    ) : login ? (
                        <div className={classes.titleRoot}>
                            <Typography variant='h4' gutterBottom>
                                FunNet
                            </Typography>
                            <Fab variant='extended' aria-label='Delete' className={classes.fab} onClick={handleLogin}>
                                <SvgIcon className={classes.extendedIcon}>
                                    <path
                                        fill='#7ad038'
                                        d='M23.541 12.748c-.609-1.38-1.758-2.476-3.092-3.151-2.354-1.192-5.281-1.185-7.629.03-1.631.837-2.993 2.337-3.379 4.162-.318 1.344-.033 2.791.68 3.961 1.061 1.762 2.979 2.887 4.971 3.248 1.443.293 2.936.119 4.338-.285.842.326 1.592.854 2.408 1.246-.211-.707-.436-1.406-.676-2.102.916-.65 1.746-1.461 2.244-2.479.744-1.415.789-3.171.135-4.63zm-9.924-9.466c-2.495-1.404-5.602-1.615-8.286-.645-1.764.635-3.36 1.815-4.346 3.42-.895 1.45-1.23 3.258-.799 4.917.433 1.84 1.711 3.383 3.262 4.413-.3.85-.585 1.699-.855 2.555.975-.51 1.95-1.043 2.926-1.561 1.17.375 2.415.559 3.66.518-.33-.943-.405-1.965-.255-2.951.225-1.371.975-2.625 1.994-3.554 1.726-1.615 4.171-2.296 6.496-2.131-.436-2.135-1.936-3.939-3.824-4.98h.027zm1.733 9.989c-.209.652-1.156.848-1.615.352-.506-.459-.309-1.418.355-1.623.734-.31 1.582.537 1.26 1.271zm4.795.092c-.256.586-1.141.723-1.576.27-.209-.191-.27-.479-.344-.73.104-.458.42-.933.93-.955.705-.098 1.336.773.975 1.416h.015zM12.99 6.909c.008.961-1.275 1.561-1.995.909-.747-.535-.535-1.837.342-2.106.785-.315 1.713.344 1.651 1.185l.002.012zm-6.059.244c-.172.835-1.291 1.238-1.946.678-.759-.535-.546-1.861.345-2.131.873-.336 1.865.55 1.601 1.453z'
                                    />
                                </SvgIcon>
                                微信登录
                            </Fab>
                        </div>
                    ) : (
                        <>
                            <LinearProgress color='secondary' />
                            <Loading />
                        </>
                    )}
                </SnackbarProvider>
            </main_config.Provider>
            <Toolbar />
            <BottomNav />
        </CssBaseline>
    )
}
