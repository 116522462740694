import { main_config } from '../../Common/MainLayout'
import React, { useEffect, useState } from 'react'
import OrderLists from '../../../components/Lists/OrderLists'
import Axios from 'axios'
import { useSnackbar } from 'notistack'

export default function Tab1() {
    const [orders, setOrders] = React.useState([])
    const { profile } = React.useContext(main_config)
    const { enqueueSnackbar } = useSnackbar()
    const [group, setGroup] = useState({})

    const [isFetch, setIsFetch] = useState(false)

    useEffect(() => {
        console.log(isFetch)
        const fetchData = async () => {
            let res = await Axios.get(`${process.env.REACT_APP_API_URL}/groups`)

            if (res.status === 200) {
                const loadGroup = res.data.find(item => {
                    return item.property === '保存'
                })
                const toGroup = res.data.find(item => {
                    return item.property === '待审'
                })

                setGroup(toGroup)
                res = await Axios.get(`${process.env.REACT_APP_API_URL}/users/${profile._id}/orders`)
                if (res.status === 200) {
                    const loadOrders = res.data.filter(item => {
                        return item.group._id === loadGroup._id
                    })
                    setOrders(loadOrders)
                    return
                }
            }
            enqueueSnackbar('获取失败，请检查网络并刷新重试!', { variant: 'error' })
        }

        fetchData()
    }, [isFetch])

    const handleUpdate = _id => async () => {
        const current = orders.find(item => {
            return item._id === _id
        })
        const { urls } = current.invoiceInfo
        if (urls.length === 0 || urls[urls.length - 1] === '') {
            enqueueSnackbar('请上传发票图片或者截图后再提交审核！', { variant: 'error' })
            return
        }
        const res = await Axios.put(`${process.env.REACT_APP_API_URL}/orders/${_id}`, {
            ...current,
            group
        })

        if (res.status === 200) {
            enqueueSnackbar('请求提交成功！', { variant: 'success' })
            setIsFetch(!isFetch)
            return
        }

        enqueueSnackbar('提交失败，请重试！', { variant: 'error' })
        setIsFetch(!isFetch)
    }

    const handleDelete = _id => async () => {
        const res = await Axios.delete(`${process.env.REACT_APP_API_URL}/orders/${_id}`)
        // if (res.status === 200) {
        //     enqueueSnackbar('删除成功！', { variant: 'success' })
        //     setIsFetch(!isFetch)
        //     return
        // }
        if (res.data.message === 'success') {
            enqueueSnackbar('删除成功！', { variant: 'success' })
            setIsFetch(!isFetch)
            return
        }
        enqueueSnackbar('删除失败，请重试！', { variant: 'error' })
        setIsFetch(!isFetch)
    }

    return (
        <React.Fragment>
            <OrderLists
                orders={orders}
                btn={profile.roles[0] === 'U' ? null : 0}
                setOrders={setOrders}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
            />
        </React.Fragment>
    )
}
