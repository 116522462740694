import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect } from 'react'
import { main_config } from '../Common/MainLayout'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles({
    titleRoot: {
        width: '100%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
    }
})

export default function Home() {
    const classes = useStyles()
    const { setTitle } = React.useContext(main_config)
    useEffect(() => setTitle('Loading...'), [setTitle])

    return (
        <div className={classes.titleRoot}>
            <Typography variant='h4' gutterBottom>
                Loading...
            </Typography>
            <Typography>如果长时间未响应请联系技术部门</Typography>
        </div>
    )
}
