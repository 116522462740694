import { CircularProgress } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import { green } from '@material-ui/core/colors'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import React from 'react'
import NumberFormat from 'react-number-format'
import FormExample from '../../assets/img/form-example.jpg'

const useStyles = makeStyles(theme => ({
    input: {
        display: 'none'
    },
    root: {
        display: 'flex',
        alignItems: 'center'
    },
    buttonCapnel: {
        marginTop: theme.spacing(2)
    },
    title: {
        fontSize: 16
    },
    textField: {
        marginBottom: theme.spacing(2)
    },
    ListFlex: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    progress: {
        margin: '0 auto'
    },
    wrapper: {
        position: 'relative',
        width: '100%'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -22,
        marginLeft: -22
    },
    gray: {
        position: 'relative',
        width: '100%',
        '&::before': {
            content: '""',
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            backgroundColor: 'rgba(0,0,0,0.2)'
        }
    }
}))

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={order => {
                onChange({
                    target: {
                        value: order.value
                    }
                })
            }}
            thousandSeparator
            prefix='￥'
        />
    )
}

export default function ReportDetails(props) {
    const classes = useStyles()
    const { order, loading, handleChange, handleUpload, token } = props

    const { invoiceInfo } = order
    const { amount, date, body, type, urls } = invoiceInfo

    const changeClass = clsx({
        [classes.gray]: loading,
        [classes.wrapper]: !loading
    })

    return (
        <React.Fragment>
            <Card>
                <CardContent>
                    <Typography className={classes.title} color='textSecondary' paragraph>
                        发票基本信息
                    </Typography>
                    <TextField
                        label='消费内容'
                        className={classes.textField}
                        type='text'
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        name='body'
                        value={body}
                        onChange={handleChange('body')}
                        placeholder='此处不能为空'
                    />
                    <TextField
                        label='开票时间'
                        className={classes.textField}
                        onChange={handleChange('date')}
                        type='date'
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        name='date'
                        value={date}
                    />
                    <TextField
                        label='消费金额'
                        className={classes.textField}
                        value={amount}
                        onChange={handleChange('amount')}
                        InputProps={{
                            inputComponent: NumberFormatCustom
                        }}
                        InputLabelProps={{
                            shrink: true
                        }}
                        fullWidth
                        name='amount'
                        placeholder='￥0'
                        autoComplete='off'
                    />
                    <FormLabel component='legend'>发票类型</FormLabel>
                    <RadioGroup
                        aria-label='type'
                        name='type'
                        value={type}
                        onChange={handleChange('type')}
                        row
                        fullWidth
                    >
                        <FormControlLabel
                            value='电子发票'
                            control={<Radio color='primary' />}
                            label='电子发票'
                            labelPlacement='end'
                        />
                        <FormControlLabel
                            value='实体发票'
                            control={<Radio color='primary' />}
                            label='实体发票'
                            labelPlacement='end'
                        />
                        <FormControlLabel
                            value='收据'
                            control={<Radio color='primary' />}
                            label='收据'
                            labelPlacement='end'
                        />
                    </RadioGroup>
                    <Typography className={classes.title} color='textSecondary' paragraph>
                        发票图片（截图）
                    </Typography>
                    <CardActionArea>
                        <Box boxShadow={1}>
                            <input
                                accept='image/*'
                                className={classes.input}
                                id='url-upload'
                                multiple
                                name='img'
                                type='file'
                                onChange={handleUpload}
                            />
                            <label htmlFor='url-upload'>
                                <div className={classes.root}>
                                    <div className={changeClass}>
                                        <CardMedia
                                            component='img'
                                            src={
                                                urls[urls.length - 1]
                                                    ? `${urls[urls.length - 1]}?${token}`
                                                    : FormExample
                                            }
                                        />
                                        {loading && (
                                            <CircularProgress className={classes.buttonProgress} color='secondary' />
                                        )}
                                    </div>
                                </div>
                            </label>
                        </Box>
                    </CardActionArea>
                </CardContent>
            </Card>
        </React.Fragment>
    )
}
