import { Button, Avatar, Chip } from '@material-ui/core'
import { Face } from '@material-ui/icons'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import NumberFormat from 'react-number-format'
import LightBoxDialog from '../Dialogs/LightBoxDialog'
import ProfileDrawer from '../Drawers/ProfileDrawer'
import OrderListDetails from './OrderListDetails'
import dateFns from 'date-fns'
import {
    Aborter,
    uploadBrowserDataToBlockBlob,
    BlockBlobURL,
    ContainerURL,
    ServiceURL,
    StorageURL,
    AnonymousCredential
} from '@azure/storage-blob'
import { useSnackbar } from 'notistack'
import Axios from 'axios'

const useStyles = makeStyles(theme => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0
    },
    secondaryHeading: {
        width: '100%',
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    panelFlex: {
        flexDirection: 'column',
        '& img': {
            marginBottom: theme.spacing(1)
        }
    },
    controls: {
        margin: theme.spacing(3)
    },
    exampleWrapper: {
        position: 'relative',
        height: 380
    },
    button: {
        marginBottom: theme.spacing(2)
    },
    overflow: {
        position: 'relative',
        overflow: 'hidden'
    }
}))

const formatDate = val => {
    const date = new Date(val)
    //return date.toDateString()
    return dateFns.format(date, 'MM月DD日')
}

export default function OrderLists(props) {
    const classes = useStyles()

    const { orders, setOrders, btn, handleDelete, handlePass, handleUpdate, handleReject } = props

    const { enqueueSnackbar } = useSnackbar()

    const [expanded, setExpanded] = React.useState(false)
    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const timer = React.useRef()
    const [loading, setLoading] = React.useState(false)

    const [url, setUrl] = React.useState('')
    const [id, setId] = React.useState('')

    const [token, setToken] = React.useState('')

    const fetchToken = async () => {
        const result = await Axios.get(`${process.env.REACT_APP_API_URL}/assets/accessToken`)
        if (result.status === 200) {
            console.log(result)
            const loadToken = result.data.sasToken
            return Promise.resolve(loadToken)
        }
        enqueueSnackbar('图片验证失败！', { variant: 'error' })
    }

    useEffect(() => {
        fetchToken().then(token => setToken(token))
    }, [])

    const [lightBox, setLightBox] = React.useState(false)
    const openLightBox = _url => () => {
        if (btn === 0) {
            return
        }
        setUrl(`${_url}?${token}`)
        setLightBox(true)
    }

    const closeLightBox = () => {
        setLightBox(false)
    }

    const [showProifle, setShowProifle] = React.useState(false)

    const handleShowProfile = _id => e => {
        e.stopPropagation()
        e.preventDefault()
        setId(_id)
        setShowProifle(true)
    }

    const closeShowProfile = () => {
        setShowProifle(false)
    }

    const handleUpload = id => async e => {
        if (e.target.files.length === 0) {
            return
        }
        setLoading(true)

        const image = e.target.files[0] || e.dataTransfer.files[0]

        fetchToken().then(token => setToken(token))

        const anonymousCredential = new AnonymousCredential()
        const pipeline = StorageURL.newPipeline(anonymousCredential)
        const _url = encodeURI(`${process.env.REACT_APP_API_IMAGE_URL}`)
        const serviceURL = new ServiceURL(`${_url}?${token}`, pipeline)
        const containerURL = ContainerURL.fromServiceURL(serviceURL, 'images')
        const blobURL = BlockBlobURL.fromContainerURL(containerURL, image.name)
        const res = await uploadBrowserDataToBlockBlob(Aborter.none, image, blobURL)

        if (res._response.status === 201) {
            const newArr = orders.map(order => {
                if (order._id === id) {
                    order.invoiceInfo.urls.push(res._response.request.url.split('?')[0])
                    return order
                }
                return order
            })
            setOrders(newArr)
            enqueueSnackbar('图片上传成功！', { variant: 'success' })
        } else {
            enqueueSnackbar('图片上传失败，请检查网络！', { variant: 'error' })
        }

        if (!loading) {
            setLoading(true)
            timer.current = setTimeout(() => {
                setLoading(false)
            }, 1000)
        }
    }

    return (
        <React.Fragment>
            {orders.map(item => {
                const { invoiceInfo, _id, createdBy, group, createdAt } = item

                return (
                    <ExpansionPanel key={_id} expanded={expanded === _id} onChange={handleChange(_id)}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} classes={{ content: classes.overflow }}>
                            {expanded === _id ? (
                                <Typography className={classes.heading}>
                                    <Chip
                                        label={createdBy.name || ''}
                                        variant='outlined'
                                        color='primary'
                                        size='small'
                                        avatar={
                                            <Avatar>
                                                <Face />
                                            </Avatar>
                                        }
                                        onClick={handleShowProfile(createdBy._id)}
                                    />
                                </Typography>
                            ) : (
                                <>
                                    <Typography className={classes.heading}>
                                        {createdBy.name}
                                        <br />
                                        {formatDate(createdAt)}
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>
                                        {invoiceInfo.body}
                                        <br />
                                        {/* <Grid container justify='space-between' wrap='nowrap'>
                                            <Grid item xs={6}> */}
                                        <NumberFormat
                                            isNumericString={true}
                                            displayType='text'
                                            thousandsGroupStyle='wan'
                                            thousandSeparator
                                            prefix='￥'
                                            value={invoiceInfo.amount}
                                        />
                                        {/* </Grid>
                                            <Grid item xs={6}>
                                                {formatDate(createdAt)}
                                            </Grid>
                                        </Grid> */}
                                    </Typography>
                                </>
                            )}
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={classes.panelFlex}>
                            {expanded === _id && (
                                <>
                                    <OrderListDetails
                                        {...item}
                                        handleShowProfile={handleShowProfile}
                                        openLightBox={openLightBox}
                                        reUpload={btn}
                                        handleUpload={handleUpload}
                                        loading={loading}
                                        token={token}
                                    />
                                    {btn === 0 && (
                                        <>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                className={classes.button}
                                                onClick={handleUpdate(_id)}
                                                disabled={group.property === '待审'}
                                            >
                                                申报
                                            </Button>
                                            <Button
                                                variant='contained'
                                                color='secondary'
                                                onClick={handleDelete(_id)}
                                                disabled={group.property === '待审'}
                                            >
                                                删除
                                            </Button>
                                        </>
                                    )}

                                    {btn === 1 && (
                                        <>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                className={classes.button}
                                                onClick={handlePass(_id)}
                                                disabled={group.property === '通过' || group.property === '已驳'}
                                            >
                                                通过
                                            </Button>
                                            <Button
                                                variant='contained'
                                                color='secondary'
                                                onClick={handleReject(_id)}
                                                disabled={group.property === '已驳' || group.property === '通过'}
                                            >
                                                驳回
                                            </Button>
                                        </>
                                    )}
                                </>
                            )}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )
            })}
            <LightBoxDialog lightBox={lightBox} closeLightBox={closeLightBox} url={url} />
            <ProfileDrawer showProifle={showProifle} closeShowProfile={closeShowProfile} id={id} />
        </React.Fragment>
    )
}
