import { Container, DialogActions, Button, DialogContent } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import Axios from 'axios'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState, useContext } from 'react'
import ProfileEditer from '../../../components/Forms/ProfileEditer'
import { main_config } from '../../Common/MainLayout'

const useStyles = makeStyles(theme => ({
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    container: {
        padding: theme.spacing(2)
    }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />
})

export default function Reported(props) {
    const classes = useStyles()
    const { editor, closeEditor = () => {} } = props

    const { profile, setProfile } = useContext(main_config)

    const [isFetch, setIsFetch] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        console.log(profile)
        ;(async () => {
            const res = await Axios.get(`${process.env.REACT_APP_API_URL}/users/${profile._id}`)
            if (res.status === 200) {
                setProfile(res.data)
                return
            }
            enqueueSnackbar('数据获取失败，请检查网络后重试！', { variant: 'error' })
        })()
    }, [isFetch])

    const handleUpdate = current => async () => {
        const res = await Axios.put(`${process.env.REACT_APP_API_URL}/users/${profile._id}`, {
            _id: profile._id,
            contact: current.contact,
            paymentAccount: current.paymentAccount,
            name: current.name
        })
        if (res.status === 200) {
            enqueueSnackbar('资料更新成功！', { variant: 'success' })
            setIsFetch(!isFetch)
            return
        }
        enqueueSnackbar('资料更新失败，请检查网络后重试！', { variant: 'error' })
    }

    return (
        <Dialog fullScreen open={editor} onClose={closeEditor} TransitionComponent={Transition}>
            <AppBar position='sticky'>
                <Toolbar>
                    <IconButton edge='start' color='inherit' onClick={closeEditor} aria-label='Close'>
                        <KeyboardBackspaceIcon />
                    </IconButton>
                    <Typography variant='h6' className={classes.title}>
                        修改资料
                    </Typography>
                </Toolbar>
            </AppBar>

            <DialogContent className={classes.container}>
                {editor && <ProfileEditer profile={profile} handleUpdate={handleUpdate} />}
            </DialogContent>
        </Dialog>
    )
}
