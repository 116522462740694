import Avatar from '@material-ui/core/Avatar'
import { deepOrange } from '@material-ui/core/colors'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import Axios from 'axios'
import React, { useEffect, useState, useContext } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useSnackbar } from 'notistack'
import { main_config } from '../../pages/Common/MainLayout'

const useStyles = makeStyles(theme => ({
    orangeAvatar: {
        margin: theme.spacing(4),
        color: '#fff',
        backgroundColor: deepOrange[500],
        width: theme.spacing(10),
        height: theme.spacing(10),
        fontSize: theme.spacing(5),
        textAlign: 'center'
    }
}))

const renderPayment = props => {
    const { paymentAccount, handleCopy } = props
    if (!paymentAccount) return null
    const { accountName, accountNumber, bankName, branchName } = paymentAccount
    return (
        <List dense disablePadding>
            <CopyToClipboard text={accountName}>
                <ListItem disableGutters onClick={handleCopy(accountName)}>
                    账户姓名：{accountName || ''}
                </ListItem>
            </CopyToClipboard>
            <CopyToClipboard text={accountNumber}>
                <ListItem disableGutters onClick={handleCopy(accountNumber)}>
                    银行账号：{accountNumber || null}
                </ListItem>
            </CopyToClipboard>
            <CopyToClipboard text={bankName}>
                <ListItem disableGutters onClick={handleCopy(bankName)}>
                    开户银行：{bankName || ''}
                </ListItem>
            </CopyToClipboard>
            <CopyToClipboard text={branchName}>
                <ListItem disableGutters onClick={handleCopy(branchName)}>
                    开户银行支行：{branchName || ''}
                </ListItem>
            </CopyToClipboard>
        </List>
    )
}

export default function ProfileDetails(props) {
    const classes = useStyles()
    const { profile } = useContext(main_config)

    const { enqueueSnackbar } = useSnackbar()

    const handleCopy = body => () => {
        enqueueSnackbar(`${body} 已复制到剪切板`, { variant: 'info' })
    }

    // eslint-disable-next-line
    const { contact, paymentAccount, roles, _id, department, name, permissions, wechat } = profile
    const { phones } = contact

    return (
        <Grid container key={_id}>
            <Grid container justify='center'>
                <Avatar className={classes.orangeAvatar}>{wechat.nickname && (wechat.nickname[0] || '')}</Avatar>
            </Grid>
            <List dense={true}>
                <ListItem>
                    <ListItemIcon>
                        <PermIdentityIcon />
                    </ListItemIcon>
                    <CopyToClipboard text={wechat.nickname || ''}>
                        <ListItemText
                            primary='微信名'
                            secondary={wechat.nickname || ''}
                            onClick={handleCopy(wechat.nickname)}
                        />
                    </CopyToClipboard>
                </ListItem>
                <ListItem>
                    <CopyToClipboard text={name || ''}>
                        <ListItemText inset primary='姓名' secondary={name || ''} onClick={handleCopy(name)} />
                    </CopyToClipboard>
                </ListItem>
                <ListItem alignItems='flex-start'>
                    <ListItemIcon>
                        <CreditCardIcon />
                    </ListItemIcon>
                    <ListItemText primary='收款信息' secondary={renderPayment({ paymentAccount, handleCopy })} />
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <PeopleOutlineIcon />
                    </ListItemIcon>
                    <CopyToClipboard text={department || ''}>
                        <ListItemText primary='部门' secondary={department || ''} onClick={handleCopy(department)} />
                    </CopyToClipboard>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <PhoneAndroidIcon />
                    </ListItemIcon>
                    <CopyToClipboard text={phones[0] || ''}>
                        <ListItemText primary='电话' secondary={phones[0] || ''} onClick={handleCopy(phones[0])} />
                    </CopyToClipboard>
                </ListItem>
                {phones.length > 0 &&
                    phones.slice(1).map((val, key) => (
                        <ListItem>
                            <CopyToClipboard text={val}>
                                <ListItemText
                                    primary={`电话${key + 1}`}
                                    inset
                                    secondary={val}
                                    onClick={handleCopy(val)}
                                />
                            </CopyToClipboard>
                        </ListItem>
                    ))}
                <ListItem>
                    <ListItemIcon>
                        <SvgIcon>
                            <path d='M23.541 12.748c-.609-1.38-1.758-2.476-3.092-3.151-2.354-1.192-5.281-1.185-7.629.03-1.631.837-2.993 2.337-3.379 4.162-.318 1.344-.033 2.791.68 3.961 1.061 1.762 2.979 2.887 4.971 3.248 1.443.293 2.936.119 4.338-.285.842.326 1.592.854 2.408 1.246-.211-.707-.436-1.406-.676-2.102.916-.65 1.746-1.461 2.244-2.479.744-1.415.789-3.171.135-4.63zm-9.924-9.466c-2.495-1.404-5.602-1.615-8.286-.645-1.764.635-3.36 1.815-4.346 3.42-.895 1.45-1.23 3.258-.799 4.917.433 1.84 1.711 3.383 3.262 4.413-.3.85-.585 1.699-.855 2.555.975-.51 1.95-1.043 2.926-1.561 1.17.375 2.415.559 3.66.518-.33-.943-.405-1.965-.255-2.951.225-1.371.975-2.625 1.994-3.554 1.726-1.615 4.171-2.296 6.496-2.131-.436-2.135-1.936-3.939-3.824-4.98h.027zm1.733 9.989c-.209.652-1.156.848-1.615.352-.506-.459-.309-1.418.355-1.623.734-.31 1.582.537 1.26 1.271zm4.795.092c-.256.586-1.141.723-1.576.27-.209-.191-.27-.479-.344-.73.104-.458.42-.933.93-.955.705-.098 1.336.773.975 1.416h.015zM12.99 6.909c.008.961-1.275 1.561-1.995.909-.747-.535-.535-1.837.342-2.106.785-.315 1.713.344 1.651 1.185l.002.012zm-6.059.244c-.172.835-1.291 1.238-1.946.678-.759-.535-.546-1.861.345-2.131.873-.336 1.865.55 1.601 1.453z' />
                        </SvgIcon>
                    </ListItemIcon>
                    <CopyToClipboard text={contact.wechat[0] || ''}>
                        <ListItemText
                            primary='微信'
                            secondary={contact.wechat[0] || ''}
                            onClick={handleCopy(wechat[0])}
                        />
                    </CopyToClipboard>
                </ListItem>
                {contact.wechat.length > 0 &&
                    phones.slice(1).map((val, key) => (
                        <ListItem>
                            <CopyToClipboard text={val}>
                                <ListItemText
                                    primary={`微信${key + 1}`}
                                    inset
                                    secondary={val}
                                    onClick={handleCopy(val)}
                                />
                            </CopyToClipboard>
                        </ListItem>
                    ))}
            </List>
        </Grid>
    )
}
