import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useEffect } from 'react'
import { main_config } from '../Common/MainLayout'

const useStyles = makeStyles(theme => ({
    titleRoot: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        textAlign: 'center'
    }
}))

export default function Home() {
    const classes = useStyles()
    const { setTitle } = React.useContext(main_config)

    useEffect(() => {
        setTitle('发票管理')
    }, [])

    return (
        <div className={classes.titleRoot}>
            <Typography variant='h4' gutterBottom>
                FunNet
            </Typography>
        </div>
    )
}
