import React from 'react'
import { Drawer } from '@material-ui/core'
import ProfileDetails from '../Lists/ProfileLists'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    paperAnchorBottom: { maxHeight: '70%' }
}))

export default function ProfileDrawer(props) {
    const { showProifle, closeShowProfile, id } = props

    const classes = useStyles()
    return (
        <Drawer
            anchor='bottom'
            open={showProifle}
            onClose={closeShowProfile}
            onBackdropClick={closeShowProfile}
            variant='temporary'
            classes={{
                paperAnchorBottom: classes.paperAnchorBottom
            }}
        >
            <ProfileDetails user={id} />
        </Drawer>
    )
}
