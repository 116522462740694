import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import React, { createContext, useEffect, useState } from 'react'
import ProfileDetails from '../../components/Lists/ProfileLists'
import { main_config } from '../Common/MainLayout'
import Reported from './Sections/Tab0'
import Editor from './Sections/Tab1.jsx'

export const toggleState = createContext(null)

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1, 0)
    },
    list: {
        width: 250
    },
    container: {
        padding: theme.spacing(2),
        margin: '0 auto'
    }
}))

export default function Profile(props) {
    const { setTitle, profile, setDisShadow } = React.useContext(main_config)
    const classes = useStyles()
    const [toggleReported, setToggleReported] = useState(false)
    const [editor, setEditor] = useState(false)

    useEffect(() => {
        setTitle('我的信息')
        setDisShadow(true)
        return () => {
            setDisShadow(false)
        }
    }, [])

    const handleClose = () => {
        setToggleReported(false)
    }

    const closeEditor = () => {
        setEditor(false)
    }

    const openEditor = () => {
        setEditor(true)
    }

    const handleUpdate = () => {}

    return (
        <Container className={classes.container} maxWidth='sm'>
            <ProfileDetails user={profile._id} />
            <Button variant='contained' color='primary' className={classes.button} fullWidth onClick={openEditor}>
                修改信息
            </Button>
            <Button
                variant='contained'
                color='primary'
                className={classes.button}
                fullWidth
                onClick={() => setToggleReported(true)}
            >
                申报记录
            </Button>
            <Editor editor={editor} closeEditor={closeEditor} handleUpdate={handleUpdate} />
            <Reported id={profile._id} handleClose={handleClose} toggleReported={toggleReported} />
        </Container>
    )
}
