import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

function RrintBody(props) {
    const { url } = props.location.query

    useEffect(() => {
        window.print()
        props.history.go(-1)
    }, [])

    return <img src={url} alt='' style={{ maxWidth: '100%', maxHeight: '100%' }} />
}
export default withRouter(RrintBody)
