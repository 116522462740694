import { Button, makeStyles } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import TextField from '@material-ui/core/TextField'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import React, { useState } from 'react'

const useStyles = makeStyles(theme => ({
    margin: {
        margin: theme.spacing(1)
    }
}))

export default function ProfileDetails(props) {
    const classes = useStyles()

    const { profile, handleUpdate = () => {} } = props

    const [current, setCurrent] = useState(profile)

    const { paymentAccount } = current
    const { contact } = current

    const handleChange = name => event => {
        setCurrent({
            ...current,
            [name]: event.target.value
        })
    }
    const handlePayment = name => event => {
        setCurrent({
            ...current,
            paymentAccount: {
                ...paymentAccount,
                [name]: event.target.value
            }
        })
    }
    const handleContact = name => event => {
        setCurrent({
            ...current,
            contact: {
                ...contact,
                phones: [event.target.value]
            }
        })
    }

    return (
        <>
            <List className={classes.margin}>
                <ListItem>
                    <ListItemIcon>
                        <PermIdentityIcon />
                    </ListItemIcon>
                    <TextField
                        fullWidth
                        label='姓名'
                        type='text'
                        value={current.name}
                        onChange={handleChange('name')}
                    />
                </ListItem>
                <ListItem alignItems='flex-start'>
                    <ListItemIcon>
                        <CreditCardIcon />
                    </ListItemIcon>
                    <List dense disablePadding>
                        <TextField
                            fullWidth
                            label='账户姓名'
                            margin='normal'
                            value={paymentAccount.accountName}
                            onChange={handlePayment('accountName')}
                            type='text'
                        />

                        <TextField
                            fullWidth
                            label='银行账号'
                            margin='normal'
                            value={paymentAccount.accountNumber}
                            onChange={handlePayment('accountNumber')}
                        />

                        <TextField
                            fullWidth
                            label='开户银行'
                            margin='normal'
                            value={paymentAccount.bankName}
                            onChange={handlePayment('bankName')}
                        />

                        <TextField
                            fullWidth
                            label='开户银行支行'
                            margin='normal'
                            value={paymentAccount.branchName}
                            onChange={handlePayment('branchName')}
                        />
                    </List>
                </ListItem>
                <ListItem>
                    <ListItemIcon>
                        <PhoneAndroidIcon />
                    </ListItemIcon>
                    <TextField
                        fullWidth
                        label='电话'
                        value={contact.phones[contact.phones.length - 1]}
                        onChange={handleContact('phones')}
                    />
                </ListItem>
            </List>
            <Button variant='contained' fullWidth color='primary' onClick={handleUpdate(current)}>
                保存
            </Button>
        </>
    )
}
