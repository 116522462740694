import React from 'react'
import { Dialog, CardMedia, DialogActions, Button, makeStyles, DialogContent } from '@material-ui/core'

import { Link } from 'react-router-dom'
const useStyles = makeStyles(theme => ({
    dialogArea: {
        width: '100%',
        margin: theme.spacing(1)
    }
}))

export default function LightBoxDialog(props) {
    const classes = useStyles()
    const { lightBox, closeLightBox, url } = props

    return (
        <Dialog
            open={lightBox}
            onClose={closeLightBox}
            classes={{
                paperScrollPaper: classes.dialogArea
            }}
        >
            <DialogContent>{lightBox && <CardMedia component='img' src={url} alt='Failed to load!' />}</DialogContent>
            <DialogActions>
                <Button
                    component={Link}
                    variant='contained'
                    color='primary'
                    to={{ pathname: '/print', query: { url } }}
                >
                    打印
                </Button>
            </DialogActions>
        </Dialog>
    )
}
