import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React, { useEffect } from 'react'
import { main_config } from '../Common/MainLayout'

const useStyles = makeStyles({
    titleRoot: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    }
})

export default function NotFound() {
    const classes = useStyles()
    const { setTitle } = React.useContext(main_config)
    useEffect(() => setTitle('Page not found!'), [setTitle])

    return (
        <Typography variant='h4' className={classes.titleRoot}>
            404
        </Typography>
    )
}
