import { CardMedia, CircularProgress, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'
import FormExample from '../../assets/img/form-example.jpg'

const useStyles = makeStyles(theme => ({
    ListFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    itemActionRoot: {
        position: 'relative',
        transform: 'none',
        top: 'unset',
        right: 'unset'
    },
    progress: {
        margin: '0 auto'
    },
    wrapper: {
        position: 'relative'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -22,
        marginLeft: -22
    },
    gray: {
        position: 'relative',
        '&::before': {
            content: '""',
            display: 'block',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            backgroundColor: 'rgba(0,0,0,0.2)'
        }
    }
}))

const formatDate = val => {
    const date = new Date(val)
    return date.toLocaleDateString('zh')
}

export default function OrderListDetails(props) {
    const {
        invoiceInfo,
        createdBy,
        group,
        operator = {
            name: ''
        },
        amount,
        approvedAmount,
        approvalDate,
        updatedAt,
        createdAt,
        handleShowProfile = () => {},
        openLightBox = () => {},
        reUpload,
        handleUpload = () => {},
        _id,
        loading,
        token
    } = props

    const classes = useStyles()

    const changeClass = clsx({
        [classes.gray]: loading,
        [classes.wrapper]: !loading
    })

    return (
        <List dense>
            <ListItem disableGutters divider>
                <ListItemText
                    primary='申报内容'
                    secondary={invoiceInfo.body}
                    classes={{
                        root: classes.ListFlex
                    }}
                />
            </ListItem>
            <ListItem disableGutters divider>
                <ListItemText
                    primary='申报日期'
                    secondary={formatDate(invoiceInfo.date)}
                    classes={{
                        root: classes.ListFlex
                    }}
                />
            </ListItem>
            {createdAt && (
                <>
                    <ListItem disableGutters divider>
                        <ListItemText
                            primary='提交审核日期'
                            secondary={formatDate(createdAt)}
                            classes={{
                                root: classes.ListFlex
                            }}
                        />
                    </ListItem>

                    <ListItem disableGutters divider>
                        <ListItemText
                            primary='最近一次修改'
                            secondary={formatDate(updatedAt)}
                            classes={{
                                root: classes.ListFlex
                            }}
                        />
                    </ListItem>
                </>
            )}
            <ListItem disableGutters divider>
                <ListItemText
                    primary='请求状态'
                    secondary={group.property}
                    classes={{
                        root: classes.ListFlex
                    }}
                />
            </ListItem>
            <ListItem disableGutters divider>
                <ListItemText
                    primary='申报金额'
                    secondary={`￥${invoiceInfo.amount || 0}`}
                    classes={{
                        root: classes.ListFlex
                    }}
                />
            </ListItem>
            {group && group.property === '已通' && (
                <>
                    <ListItem disableGutters divider>
                        <ListItemText
                            primary='通过金额'
                            secondary={`￥${approvedAmount}(${approvedAmount - amount})`}
                            classes={{
                                root: classes.ListFlex
                            }}
                        />
                    </ListItem>
                    <ListItem disableGutters divider>
                        <ListItemText
                            primary='操作人'
                            secondary={operator.name || ''}
                            classes={{
                                root: classes.ListFlex
                            }}
                        />
                    </ListItem>
                    <ListItem disableGutters divider>
                        <ListItemText
                            primary='通过日期'
                            secondary={formatDate(approvalDate)}
                            classes={{
                                root: classes.ListFlex
                            }}
                        />
                    </ListItem>
                </>
            )}
            <ListItem disableGutters divider>
                <ListItemText
                    primary='发票类型'
                    secondary={invoiceInfo.type || ''}
                    classes={{
                        root: classes.ListFlex
                    }}
                />
            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primary='发票图片（截图）'
                    classes={{
                        root: classes.ListFlex
                    }}
                />
            </ListItem>
            <ListItem alignItems='center'>
                <ListItemSecondaryAction
                    onClick={openLightBox(invoiceInfo.urls[invoiceInfo.urls.length - 1])}
                    classes={{
                        root: classes.itemActionRoot
                    }}
                >
                    <div className={classes.root}>
                        <div className={changeClass}>
                            <input
                                accept='image/*'
                                style={{ display: 'none' }}
                                id='url-upload'
                                multiple
                                name='img'
                                type='file'
                                onChange={handleUpload(_id)}
                            />
                            <label htmlFor={reUpload === 0 ? 'url-upload' : ''}>
                                <CardMedia
                                    component='img'
                                    src={
                                        invoiceInfo.urls.length > 0
                                            ? `${invoiceInfo.urls[invoiceInfo.urls.length - 1]}?${token}`
                                            : FormExample
                                    }
                                    alt='Failed to load!'
                                />
                                {loading && <CircularProgress className={classes.buttonProgress} color='secondary' />}
                            </label>
                        </div>
                    </div>
                </ListItemSecondaryAction>
            </ListItem>
        </List>
    )
}
