import { AppBar } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import React, { useEffect } from 'react'
import Section0 from './Sections/Tab0'
import Section1 from './Sections/Tab1'
import { main_config } from '../Common/MainLayout'

const useStyles = makeStyles(theme => ({
    barFixed: {
        top: theme.spacing(7),
        zIndex: 1
    },
    margin: {
        padding: theme.spacing(2),
        margin: '0 auto'
    }
}))

export default function Report(props) {
    const classes = useStyles()
    const { setTitle } = React.useContext(main_config)

    const [index, setIndex] = React.useState(0)

    useEffect(() => {
        setTitle('信息录入')
    }, [])

    const handleChange = (event, newValue) => {
        setIndex(newValue)
    }

    return (
        <React.Fragment>
            <AppBar position='sticky' className={classes.barFixed} color='primary'>
                <Tabs value={index} onChange={handleChange} indicatorColor='secondary' variant='fullWidth'>
                    <Tab label='新的申请' />
                    <Tab label='已保存' />
                </Tabs>
            </AppBar>
            <Container className={classes.margin} maxWidth='sm'>
                {index === 0 && <Section0 />}
                {index === 1 && <Section1 />}
            </Container>
        </React.Fragment>
    )
}
